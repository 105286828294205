<template>
  <div>
    <b-card >
      <b-link @click="$router.push({name: 'tracking_links'})" class="mb-0 mb-sm-2 d-block">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        {{$t('tracking.goBack')}}
      </b-link>
      <div class="button-update-links">
        <b-button variant="btn_action" class="btn_action" id="tooltip_print_link" @click="printAction"><feather-icon icon="PrinterIcon"></feather-icon></b-button>
        <b-tooltip target="tooltip_print_link" triggers="hover">{{$t('tracking.printView')}}</b-tooltip>
        <b-button variant="btn_action" id="tooltip_edit_link" class="btn_action" @click="editLink"><feather-icon icon="EditIcon"></feather-icon></b-button>
        <b-tooltip target="tooltip_edit_link" triggers="hover">{{$t('tracking.editLink')}}</b-tooltip>
        <b-button variant="btn_action" id="tooltip_delete_link" class="btn_action" @click="archiveLink">
          <feather-icon icon="ArchiveIcon" v-if="link.status === 'active'"></feather-icon>
          <b-icon v-else icon="arrow-up-square" class="arrow-up-square-icon"></b-icon>
        </b-button>
        <b-tooltip target="tooltip_delete_link" triggers="hover">{{link.status === 'active' ? 'Archived' : 'Unarchived'}} link</b-tooltip>
        <b-button variant="btn_action" id="tooltip_update_links" @click="updateLink" class="btn_action"><feather-icon icon="RepeatIcon"></feather-icon></b-button>
        <b-tooltip target="tooltip_update_links" triggers="hover">{{$t('tracking.updateLInk')}}</b-tooltip>
      </div>
      <h2 v-if="link.name">{{$t('tracking.trackingFor')}} {{link.name}}</h2>
      <div class="my-2 mb-4 mb-md-2">
        <div class="mb-1">
          <span class="position-relative">
            <span class="container-destination-link">{{link.shorten_link}}</span>
            <feather-icon icon="CopyIcon" class="copy-icon" size="23" id="tooltip_short" @click="copyAction(link.shorten_link)"></feather-icon>
            <b-tooltip target="tooltip_short">{{$t('tracking.copyShort')}}</b-tooltip>
          </span>
        </div>
        <div>
          <span class="position-relative">
            <span class="container-destination-link">{{link.original_link}}</span>
            <feather-icon icon="CopyIcon" class="copy-icon" size="23" id="tooltip_original" @click="copyAction(link.original_link)"></feather-icon>
            <b-tooltip target="tooltip_original">{{$t('tracking.copyLink')}}</b-tooltip>
          </span>
        </div>
      </div>
      <div class="position-relative">
        <div class="date-links-filter">
          <b-button variant="flat-secondary mr-1" @click="clearFilter"><feather-icon icon="TrashIcon" class="mr-04"></feather-icon>{{$t('tracking.clearFilter')}}</b-button>
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="data"
            v-model="date_range"
          >
          <template v-slot:input>
            <div class="d-flex align-items-center text-dropdown">
              <b-icon icon="calendar3" class="mr-04" v-if="isDateRangeNull()"/>
              {{getTextDropdown()}}
            </div>
          </template> 
          <template #ranges="ranges">
            <div class="ranges">
              <ul>
                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                  <b>{{ $t(`creator.${name}`)}}</b>
                </li>
              </ul>
            </div>
          </template>
          </date-range-picker>
        </div>
        <b-tabs>
          <b-tab :title="$t('tracking.statistics')">
            <insights-graphics :data="metrics" v-if="loaded_metrics"></insights-graphics>
          </b-tab>
          <b-tab :title="$t('tracking.quality')" disabled></b-tab>
          <b-tab :title="$t('tracking.activity')" class="responsive-tabs">
            <click-activity class="responsive-tabs" :data="metrics" v-if="loaded_metrics"/>
          </b-tab>
        </b-tabs>
      </div>
       <div class="container-loader-searcher loader-table-cards" v-if="!loaded_metrics">
        <b-spinner class="spinner-table-searcher"></b-spinner>
      </div>
    </b-card>
    <modal-add-link 
      :link="link_to_edit" 
      :open_modal="open_modal" 
      :key="update_modal"
      @update_data="updateEditedLink"
      @close_modal="closeModal"
    />
  </div>
</template>

<script>
import {
  BIcon,
  BCard,
  BTooltip,
  BTab,
  BTabs,
  BSpinner,
  BLink,
  BButton,
} from 'bootstrap-vue';
import tracking_service from '@/services/tracking_links';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import insightsGraphics from '@/views/pages/tracking-links/analytics/insightsGraphics.vue';
import modalAddLink from '@/views/pages/tracking-links/modalAddLink.vue';
import clickActivity from '@/views/pages/tracking-links/analytics/clickActivity.vue';
import DateRangePicker from 'vue2-daterange-picker';
import utils from '@/libs/utils';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { print } from '@/libs/utils/others';

export default {
  name: 'detailTrackingLinks',
  components: {
    BIcon,
    BCard,
    BTooltip,
    BTab,
    BTabs,
    BSpinner,
    BLink,
    BButton,
    insightsGraphics,
    modalAddLink,
    clickActivity,
    DateRangePicker
  },
  data() {
    return {
      utils,
      loaded_activity: false,
      link: {},
      metrics: {},
      loaded_metrics: false,
      open_modal: false,
      update_modal: false,
      link_to_edit: {},
      loading_printer: false,
      date_range: {
        startDate: null,
        endDate: null
      },
      data: {
        applyLabel: this.$t('tracking.apply'),
        cancelLabel: this.$t('tracking.cancel'),
      },
    }
  },
  created() {
    this.getLink();
  },
  methods: {
    loaderGraphics() {
      this.getMetricsLink();
    },
    clearFilter() {
      this.loaded_metrics = false; 
      this.getMetricsLink();
      this.date_range = {
        startDate: null,
        endDate: null
      }
    },
    isDateRangeNull() {
      let is_null = true;
      for (const i in this.date_range) {
        if (this.date_range[i] !== null) {
          is_null = false;
          break;
        }
      }
      return is_null;
    },
    getTextDropdown() {
      if (this.isDateRangeNull()) {
        return  this.$t('tracking.anyDate')
      } else {
        return `${this.$t('tracking.filteredBy')} ${utils.getDatev2(new Date(this.date_range.startDate))} - ${utils.getDatev2(new Date(this.date_range.endDate))}`
      }
    },
    getFormatClicksStatistics(data) {
      const arr = [...data];
      arr.sort((a, b) => new Date(b.x) - new Date(a.x));
      return arr;
    },
    closeModal() {
      this.open_modal = false; 
      this.update_modal = !this.update_modal
    },
    printAction() {      
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(function() {
        print('p', `Analytics-links`, document.body, function () {
          self.checkButton = false;
          self.btnClasses = 'cursor-pointer-mt-10';
          self.$emit('childEnableEmbed', {
            section: self.section
          })
        })
      }, 500);
    },
    editLink() {
      this.link_to_edit = {
        link: this.link.original_link,
        name: this.link.name !== null ? this.link.name : '',
        utm_source: this.link.utm_source !== null ? this.link.utm_source : '',
        utm_medium: this.link.utm_medium !== null ? this.link.utm_medium : '',
        utm_campaign: this.link.utm_campaign !== null ? this.link.utm_campaign : '',
        utm_content: this.link.utm_content !== null ? this.link.utm_content : '',
        uuid: this.link.uuid
      };
      this.open_modal = true;
      this.update_modal = !this.update_modal;
    },
    updateEditedLink(data) {
      this.closeModal();
      this.link = data;
    },
    archiveLink() {
      this.$swal({
        title: `${this.$t('tracking.sure?')}  ${this.link.status === 'active' ? this.$t('tracking.archiving') : this.$t('tracking.unarchiving')} ${this.$t('tracking.thisLink')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t('tracking.btnYes')} ${this.link.status === 'active' ? this.$t('tracking.archiving') : this.$t('tracking.unarchiving')}`,
        cancelButtonText: 'No',
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          tracking_service.toggleStatusShortLinks(this.link.uuid).then(() => {
            this.$router.push({name: 'tracking_links', query: {status: `${this.link.status === 'active' ? 'archived' : 'active'}`}});
          });
        }
      });
    },
    updateLink() {
      this.getLink();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('tracking.updateInformation'),
          icon: "BellIcon",
          text: this.$t('tracking.updateLInk'),
          variant: 'success'
        },
      });
    },
    getLink() {
      tracking_service.detailShortLink(this.$route.params.tracking_uuid).then((response) => {
        if (Number.isInteger(response.status)) {
          if (response.data.response.code === 'resource.forbidden') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('campaigns.Error'),
                icon: "BellIcon",
                text: this.$t('tracking.resource_forbidden'),
                variant: 'danger'
              },
            });
            this.$router.push({name: 'dashboard-analytics'})
          }

        } else {
          this.link = response;
          this.getMetricsLink();
        }
      });
    },
    copyAction(link) {
      this.$copyText(link).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('campaigns.copied'),
            icon: "CopyIcon",
            text: this.$t('campaigns.clipboard'),
            variant: 'success'
          },
        });
      });
    },
    getMetricsLink(filters = {}) {
      tracking_service.getMetricsShortLink(this.$route.params.tracking_uuid, filters).then((response) => {
        this.metrics = response;
        this.loaded_metrics = true;
      });
    },
  },
  watch: {
    'date_range'(val) {
      this.loaded_metrics = false;
      if (!this.isDateRangeNull()) {
        const filters = {
          period: 'custom',
          start_date: utils.getDatev4(new Date(val.startDate)),
          end_date: utils.getDatev4(new Date(val.endDate))
        }
        this.getMetricsLink(filters);
      }
    }
  }
}
</script>

<style>
.btn_action {
  color: #7367f0;
}

.btn_action:hover {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.2);
}
.daterangepicker .active {
  background:  #3483FA !important;
  border: none !important;
}
.btn-success {
  background:  #3483FA !important;
  border: none !important;
}
.container-destination-link {
  overflow: hidden;
  color: #3483FA;
}
.mr-04 {
  margin-right: 0.4em;
}
.arrow-up-square-icon {
  width: 14px;
  height: 14px;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 4em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.copy-icon {
  position: absolute;
  right: -25px;
  background-color: #f2f2f2;
  color: #b5b5b5;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}
.copy-icon:hover {
  background-color: #E0F1F6;
  color: #6DAECA;
}
.button-update-links {
  position: absolute;
  right: 1em;
  top: 1em;
}
.date-links-filter {
  position: absolute;
  right: 1em;
  top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.text-dropdown {
  color: #82868b;
  font-weight: 500;
}
.vue-daterange-picker .form-control, .vue-daterange-picker .reportrange-text {
  border: 1px solid transparent !important;
  height: auto;
}
@media(max-width: 410px) {
  .button-update-links {
    display: block;
    position: relative;
    margin-bottom: 2em;
  }
  .date-links-filter {
    position: relative;
    justify-content: center;
  }
}
@media(max-width: 808px){
  .date-links-filter {
    top: -36px;
  }
}
</style>