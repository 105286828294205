<template>
  <b-row>
    <b-col>
      <b-card no-body class="text-center">
        <b-row class="m-0">
          <div class="mb-2 row w-100 p-2 border rounded-lg ml-0 mr-1">
            <b-col id="total-clicks" class="col-12 col-md-6 col-lg-3">
              <strong class="text-muted">{{$t('tracking.totalClicks')}}</strong>
              <h2 class="mt-1 style-clics">{{utils.numberFormat(data.totalClicks)}}</h2>
              <b-tooltip target="total-clicks">{{$t('tracking.clicksInterval')}}</b-tooltip>
            </b-col>
            <b-col id="human-clicks" class="col-12 col-md-6 col-lg-3">
              <strong class="text-muted">{{$t('tracking.clicksHuman')}}</strong>
              <h2 class="mt-1 style-clics">{{utils.numberFormat(data.humanClicks)}}</h2>
              <b-tooltip target="human-clicks">{{$t('tracking.clicksHumanInterval')}}</b-tooltip>
            </b-col>
            <b-col id="human-clicks-change" class="col-12 col-md-6 col-lg-3">
              <strong class="text-muted">{{$t('tracking.clicksHumanChange')}}</strong>
              <h2 class="mt-1 style-clics">{{data.humanClicksChange === '999' ? $t('tracking.notAvailable') : utils.numberFormat(data.humanClicksChange) }}</h2>
              <b-tooltip target="human-clicks-change">{{$t('tracking.clicksDiference')}}</b-tooltip>
            </b-col>
            <b-col id="total-clicks-change" class="col-12 col-md-6 col-lg-3">
              <strong class="text-muted">{{$t('tracking.totalClicksChange')}}</strong>
              <h2 class="mt-1 style-clics">{{ data.totalClicksChange === '999' ? $t('tracking.notAvailable') : utils.numberFormat(data.totalClicksChange) }}</h2>
              <b-tooltip target="total-clicks-change">{{$t('tracking.clicksDiference')}}</b-tooltip>
            </b-col>
          </div>
          <b-col class="col-12 pl-0">
            <b-overlay 
              :show="!loaded_graphic_clicks"
              variant="ligth"
              opacity="1"
              blur="1rem">
            <div class="border rounded-lg h-100 p-1">
              <h4>{{$t('tracking.clickStatics')}}</h4>
              <vue-apex-charts 
                type="scatter" 
                height="350" 
                :options="chart_options_scatter" 
                :series="series_scatter"
              ></vue-apex-charts>
            </div>
            </b-overlay>
          </b-col>
           <b-col class="col-12 col-xl-6 col-md-6 pt-1 p-0 pr-1 h-350 mb-2" v-if="data.totalClicks > 0">
            <div class="border p-1 rounded-lg">
              <h4>{{$t('tracking.location')}}</h4>
              <div class="bars mt-2">
                <div v-for="(country, index) in data.country.slice(0, 5)" :key="`country_${index}`" class="row mb-1">
                  
                  <span class="d-none col-md-2 col-lg-4 col-xl-4 d-lg-flex align-items-center"><country-flag-svg class="mr-1" :country="country.country"/>
                    <span class="title-country-bar d-none d-md-block">{{country.countryName}}</span>
                  </span>
                  <div class="col-12 col-lg-7 col-xl-7">
                    <div class="bar-countries position-relative" :style="`width: ${parseFloat(utils.numberFormat((country.score * 100) / summationScore(data)))}%`">
                      <country-flag-svg :country="country.country" class="d-lg-none mt-08"/>
                      <div class="score-percentage d-none d-lg-block">
                        {{parseFloat(utils.decimalFixed(((country.score * 100) / summationScore(data)), 2))}} %
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(country, index) in data.country.slice(0, 5)" :key="index" class="d-lg-none mb-1">
                  <span class="col-12 d-flex align-items-center"><country-flag-svg :country="country.country"/>
                    <span class="title-country-bar">{{country.countryName + ' - ' + parseFloat(utils.decimalFixed(((country.score * 100) / summationScore(data)), 2)) + ' %'}}</span>
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="col-12 col-lg-6 p-0 pt-1 p-0 pr-1 h-350 mb-2">
            <b-overlay 
              :show="!loaded_graphic"
              variant="ligth"
              opacity="1"
              blur="1rem"
            >
              <div class="border p-1 rounded-lg">
                <h4>{{$t('tracking.heatGraph')}}</h4>
                <vue-apex-charts
                  :key="ready_heat_data"
                  type="heatmap"
                  height="350"
                  :options="heatMapChart.chartOptions"
                  :series="heatMapChart.series"
                />
              </div>
            </b-overlay>     
          </b-col>   
          <b-col class="col-12 col-lg-6 p-0 pr-1 mb-2" v-if="data.totalClicks > 0">
            <div class="border p-1 rounded-lg">
              <b-tooltip target="total">{{$t('tracking.clicksInterval')}}</b-tooltip>
              <h4 id="total" class="style-clics">{{$t('tracking.totalClicks')}}</h4>
              <vue-apex-charts
                height="235"
                class="cursor-pointer"
                type="donut"
                :options="options_total_clicks"
                :series="series_total_click"
                v-if="change_graph"
              ></vue-apex-charts>
              <div class="row mt-1">
                <b-col class="mt-2 col-6">
                  <b-tooltip target="click-valid">{{$t('tracking.tooltipValid')}}</b-tooltip>
                  <strong class="text-muted" id="click-valid">{{$t('tracking.clickValid')}}</strong>
                  <h3 class="mt-1 style-clics">{{utils.numberFormat(data.humanClicks)}}</h3>
                </b-col>
                <b-col class="mt-2 col-6">
                  <b-tooltip target="click-suspicius">{{$t('tracking.tooltipSuspicius')}}</b-tooltip>
                  <strong class="text-muted" id="click-suspicius">{{$t('tracking.clickSuspicius')}}</strong>
                  <h3 class="mt-1 style-clics">{{utils.numberFormat(data.totalClicks - data.humanClicks)}}</h3>
                </b-col>
              </div>
            </div>
          </b-col>
          <b-col class="col-12 col-lg-6 p-0 mb-2 pr-1" v-if="data.totalClicks > 0">
            <div class="border p-1 rounded-lg">
              <h4>{{$t('tracking.so')}}</h4>
              <vue-apex-charts
                height="235"
                class="cursor-pointer"
                :options="options_os"
                :series="series_os"
              ></vue-apex-charts>
              <div class="row mt-1 justify-content-between">
                <b-col class="mt-2 col-12 col-sm-6 col-lg-3" v-for="(os, index) in data.os" :key="index">
                  <strong class="text-muted">{{os.os}}</strong>
                  <h3 class="mt-1 style-clics">{{utils.numberFormat(os.score)}}</h3>
                </b-col>
              </div>
            </div>
          </b-col>
          <b-col v-else class="h1 mt-5">
            {{$t('tracking.show')}}
          </b-col>
          <b-col class="col-12 col-lg-6 p-0 pr-1 mb-2" v-if="data.totalClicks > 0">
            <div class="border p-1 rounded-lg">
              <b-tooltip target="total">{{$t('tracking.clicksInterval')}}</b-tooltip>
              <h4 id="total" class="style-clics">{{$t('tracking.totalDevice')}}</h4>
              <vue-apex-charts
                height="235"
                class="cursor-pointer"
                :options="options_total_devices"
                :series="series_total_device"
              ></vue-apex-charts>
              <div class="row mt-1">
                <b-col class="mt-2 col-6">
                  <b-tooltip target="click-valid">{{$t('tracking.tooltipValid')}}</b-tooltip>
                  <strong class="text-muted" id="click-valid">{{$t('tracking.desk')}}</strong>
                  <h3 class="mt-1 style-clics">{{utils.numberFormat(data.browser_format[0].score)}}</h3>
                </b-col>
                <b-col class="mt-2 col-6">
                  <strong class="text-muted" id="click-suspicius">{{$t('tracking.mobile')}}</strong>
                  <h3 class="mt-1 style-clics">{{utils.numberFormat(data.browser_format[1].score)}}</h3>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { 
  BOverlay,
  BRow, 
  BCol, 
  BCard,
  BTooltip,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import utils from '@/libs/utils';
import tracking_service from '@/services/tracking_links';
import countryFlagSvg from '@/views/components/countryFlagSvg.vue';

export default {
  name: 'insightsGraphics',
  components: {
    BOverlay,
    countryFlagSvg,
    BRow,
    BCol,
    BCard,
    BTooltip,
    VueApexCharts
  },
  props: {
    data: {
      required: true
    },
  },
  data () {
    return {
      utils,
      loaded_graphic_clicks: false,
      loaded_graphic: false,
      show_total: false,
      show_click: false,
      series_total_click: [],
      series_total_device: [
        parseFloat(utils.decimalFixed((this.data.browser_format[0].score * 100) / (this.data.browser_format[0].score + this.data.browser_format[1].score))),
        parseFloat(utils.decimalFixed((this.data.browser_format[1].score * 100) / (this.data.browser_format[0].score + this.data.browser_format[1].score)))
      ],
      options_total_devices: {
        labels: [this.$t('tracking.desk'),this.$t('tracking.mobile')],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val} %`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: ['#FCD147', '#81C8DF'],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val} %`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t('tracking.mobile'),
                  formatter: () => { return `${utils.decimalFixed((this.data.browser_format[1].score * 100) / (this.data.browser_format[0].score + this.data.browser_format[1].score))} %` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      options_total_clicks: {
        labels: [],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val} %`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: [],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val} %`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t('tracking.clickValid'),
                  formatter: () => { return `${utils.decimalFixed((this.data.humanClicks * 100) / this.data.totalClicks,2)} %` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      series_os: this.data.os.length > 0 ? [{name: 'os', data: this.data.os.map((item) => parseFloat(utils.decimalFixed((item.score * 100) / this.data.totalClicks,2)))}] : [],
      options_os: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '55 %',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.data.os.length > 0 ? this.data.os.map((item) => item.os) : [],
          labels: {
            formatter: () => { return '' },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => { return `${val} %` },
          },
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#7FFF00', '#FF69B4'],
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val} %`
            },
          },
        },
      },
      chart_options_scatter: {
        chart: {
          id: "datetime-scatter",
          type: "scatter",
          zoom: {
            enabled: true,
            offsetY: 20,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: '<b-icon/>',
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
            }
          },

        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        yaxis: {
          min: 0,
          max: 100,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      heatMapChart: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
            position: 'bottom',
          },
          colors: ['#7367F0'],
          plotOptions: {
            heatmap: {
              enableShades: false,
              colorScale: {
                inverse: true,
                ranges: [
                  {
                    from: 0,
                    to: 100,
                    name: 'low',
                    color: '#b9b3f8',
                  },
                  {
                    from: 101,
                    to: 1000,
                    name: 'Medium',
                    color: '#aba4f6',
                  },
                  {
                    from: 1001,
                    to: 99999999,
                    name: 'High',
                    color: '#9d95f5',
                  }
                ],
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: false,
            },
          },
        },
      },
      ready_heat_data: false
    }
  },
  created() {
    this.getHeatData()
    const clicks_human = parseFloat(utils.decimalFixed((this.data.humanClicks * 100) / this.data.totalClicks,2))
    if (clicks_human > 0) {
      this.series_total_click.push(clicks_human)
      this.options_total_clicks.labels.push(this.$t('tracking.clickValid'))
      this.options_total_clicks.colors.push('#00a4ae')
    }
    const clicks_suspects = parseFloat(utils.decimalFixed(((this.data.totalClicks - this.data.humanClicks) * 100) / this.data.totalClicks,2))
    if (clicks_suspects > 0) {
      this.series_total_click.push(clicks_suspects)
      this.options_total_clicks.labels.push(this.$t('tracking.clickSuspicius'))
      this.options_total_clicks.colors.push('#E75480')
    }
    this.change_graph = true
  },
  computed: {
    series_scatter() {
      const serie = [
        {
          name: this.$t('tracking.clickStatic'),
          data: []
        }
      ];
      this.data.clickStatistics.datasets[0].data.map((stat) => {
        serie[0].data.push([this.getDate(new Date(stat.x)), stat.y])
        this.loaded_graphic_clicks = true
      });
      return serie
    }
  },
  methods: {

    summationScore(data) {
      let totalScore = 0;
      for (let i = 0; i < data.country.length; i++) {
        totalScore += data.country[i].score;
      }
      return totalScore
    },
    getHeatData() {
      const filters = {
        "interval": "hour",
        "period": "last7"
      }

      tracking_service.getMetricsShortLink(this.$route.params.tracking_uuid, filters).then((response) => {
        const series = []
        if (response.clickStatistics && response.clickStatistics.datasets) {
          const data = response.clickStatistics.datasets[0].data
          data.forEach(element => {
            const date = new Date(element.x)
            const day = date.toLocaleDateString('es-ES', { weekday: 'long' })
            const ready_day = series.find(i => i.real_name_day === day)

            if (ready_day) {
              const ready_hour = ready_day.data.find(i => i.x ===  `${date.getHours()}:00`)
              if (!ready_hour) {
                ready_day.data.push({
                  x: `${date.getHours()}:00`,
                  y: element.y,
                  value_hour: date.getHours()
                })
                ready_day.data.sort((a, b) => { return a.value_hour - b.value_hour })
              }
            } else {
              const month = date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`
              series.push({
                name: `${utils.capitalize(day)} ${date.getDate()}-${this.$t(`profile.months.${month}`)}`,
                real_name_day: day,
                data: [{x: `${date.getHours()}:00`, y: element.y, value_hour: date.getHours()}]
              })
            }
          });

          this.heatMapChart.series = series
        }

        setTimeout(() => {
          this.ready_heat_data = !this.ready_heat_data;
          this.loaded_graphic = true
        }, 50)
      });
    },
    getDate(date) {
      const month = date.getMonth() + 1;
      const string_month = month < 10 ? `0${month}` : `${month}`

      return `${string_month}/${date.getDate()}/${date.getFullYear()}`
    },
  }
}
</script>
<style>
.mt-08 {
  margin-top: 8px;
}
.style-clics {
  font-size: 24px !important;
  color: #545454 !important;
}
.ml-01 {
  margin-left: 0.4em !important;
}
.bars {
  height: 350px;
}
.title-country-bar {
  margin-left: 0.7em !important;
}
.bar-countries {
  height: 39px;
  background-color:#f03949;
}
.score-percentage {
  background-color: #f03949;
  border-radius: 0em 1em 1em 0em;
  padding: 1.05em;
  font-size: 11px;
  font-weight: bolder;
  position: absolute;
  right: -4.6em;
  color: white;
  top: 0em;
}
.flag-absolute-sm {
  position: absolute;
  left: 1em;
  /* padding: 1.05em; */
  /* border-radius: 1em; */
  background-color: #f03949;
}
</style>