<template>
  <div>
    <b-row>
    <b-col class="col-12 p-0 mb-2 pr-1" v-if="data.totalClicks > 0">
      <b-overlay
        :show="overlay_general"
        variant="ligth"
        opacity="1"
        blur="1rem"
      >
        <div class="border p-1 rounded-lg">
          <h4>{{$t('tracking.city')}}</h4>
          <vue-apex-charts
            height="235"
            class="cursor-pointer"
            :options="options_city"
            :series="series_city"
            v-if="!overlay_general"
          ></vue-apex-charts>
          <div class="row mt-1 justify-content-between text-center">
            <b-col class="mt-2 col-12 col-sm-6 col-lg-3" v-for="(city, index) in data.city" :key="index">
              <strong class="text-muted">{{city.name}}</strong>
              <h3 class="mt-1 style-clics">{{utils.numberFormat(city.score)}}</h3>
            </b-col>
          </div>
        </div>
      </b-overlay>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 p-0 mb-2 pr-1" v-if="data.totalClicks > 0">
      <b-overlay
        :show="overlay_general"
        variant="ligth"
        opacity="1"
        blur="1rem"
      >
        <div class="border p-1 rounded-lg">
          <h4>{{$t('tracking.networks')}}</h4>
          <vue-apex-charts
            height="235"
            class="cursor-pointer"
            :options="options_net"
            :series="series_net"
            v-if="!overlay_general"
          ></vue-apex-charts>
          <div class="row mt-1 justify-content-between text-center">
            <b-col class="mt-2 ml-2 col-12 col-sm-6 col-lg-3" v-for="(social, index) in data.social" :key="index">
              <strong class="text-muted">{{social.social}}</strong>
              <h3 class="mt-1 style-clics">{{utils.numberFormat(social.score)}}</h3>
            </b-col>
          </div>
        </div>
      </b-overlay>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 p-0 mb-2 pr-1" v-if="data.totalClicks > 0">
      <b-overlay
        :show="overlay_general"
        variant="ligth"
        opacity="1"
        blur="1rem"
      >
        <div class="border p-1 rounded-lg">
          <h4>{{$t('tracking.referer')}}</h4>
          <vue-apex-charts
            height="235"
            class="cursor-pointer"
            :options="options_referer"
            :series="series_referer"
            v-if="!overlay_general"
          ></vue-apex-charts>
          <div class="row mt-1 justify-content-between text-center">
            <b-col class="mt-2 ml-2 col-12 col-sm-6 col-lg-3" v-for="(referer, index) in data.referer" :key="index">
              <strong class="text-muted">{{referer.referer}}</strong>
              <h3 class="mt-1 style-clics">{{utils.numberFormat(referer.score)}}</h3>
            </b-col>
          </div>
        </div>
      </b-overlay>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { BCol, BRow, BOverlay } from 'bootstrap-vue';
import utils from '@/libs/utils';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'clickActivity',
  components: {
    BRow,
    BCol,
    BOverlay,
    VueApexCharts,
  },
  props: {
    data: {
      required: true
    },
  },
  data() {
    return {
      utils,
      overlay_general: true,
      series_city: this.data.city.length > 0 ? [{name: 'Porcentaje', data: this.data.city.map((item) => parseFloat(utils.decimalFixed((item.score * 100) / this.data.totalClicks,2)))}] : [],
      options_city: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '55 %',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.data.city.length > 0 ? this.data.city.map((item) => item.name) : [],
          labels: {
            formatter: () => { return '' },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => { return `${val} %` },
          },
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#7FFF00', '#FF69B4'],
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val} %`
            },
          },
        },
      },
      series_referer: this.data.referer.length > 0 ? [{name: 'Porcentaje', data: this.data.referer.map((item) => parseFloat(utils.decimalFixed((item.score * 100) / this.data.totalClicks,2)))}] : [],
      options_referer: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '55 %',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.data.referer.length > 0 ? this.data.referer.map((item) => item.referer) : [],
          labels: {
            formatter: () => { return '' },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => { return `${val} %` },
          },
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#7FFF00', '#FF69B4'],
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val} %`
            },
          },
        },
      },
      series_social: this.data.social.length > 0 ? [{name: 'Porcentaje', data: this.data.social.map((item) => parseFloat(utils.decimalFixed((item.score * 100) / this.data.totalClicks,2)))}] : [],
      options_social: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '55 %',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.data.social.length > 0 ? this.data.social.map((item) => item.social) : [],
          labels: {
            formatter: () => { return '' },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => { return `${val} %` },
          },
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#7FFF00', '#FF69B4'],
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val} %`
            },
          },
        },
      },
      series_net: [],
      options_net: {
        labels: [],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val} %`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: [],
        plotOptions: {
          pie: {
            startAngle: 10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val} %`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: 'Instagram',
                  formatter: () => { return `${utils.decimalFixed((this.data.social[0].score * 100) / this.summationScore(this.data),2)} %` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
    }
  },
  created() {
    this.netSeries()
  },
  methods: {
    getNetworkColor(net) {
      const network_colors = {
        twitter: '#40AAEC',
        instagram: '#CD4D4D',
        facebook: '#1A66F3',
        youtube: '#CF302B',
        tiktok: '#EC3F79',
        blog: '#F0A23F',
        linkedin: '#346297',
        spotify: '#64D862',
        pinterest: '#E73631',
        twitch: '#924DF7',
        snapchat: '#F5D90D'
      }
      return network_colors[net]
    },
    netSeries() {
      const colors = []
      const labels = []
      const series = []
      this.data.social.forEach(net => {
        const percentage = utils.decimalFixed((net.score * 100) / this.summationScore(this.data),2);
        labels.push(net.social)
        series.push(parseFloat(percentage))
        colors.push(this.getNetworkColor(net.social.toLowerCase()))
      })
      this.series_net = series
      this.options_net.colors = colors
      this.options_net.labels = labels

      setTimeout(() => {
        this.overlay_general = false
      }, 750)
    },
    summationScore(data) {
      let totalScore = 0;
      for (let i = 0; i < data.social.length; i++) {
        totalScore += data.social[i].score;
      }
      return totalScore
    },
  }
}
</script>
